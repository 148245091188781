import { useTheme } from '@emotion/react';
import ProfilePlaceHolder from 'assets/images/other/profile-placeholder.jpg';
import { ReactComponent as FaceBookIcon } from 'assets/images/social_icon/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/social_icon/instagram.svg';
import { ReactComponent as LinkedInIcon } from 'assets/images/social_icon/linkedin.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/social_icon/x.svg';
import { ReactComponent as YoutubeIcon } from 'assets/images/social_icon/youtube.svg';

import {
  Avatar,
  Box,
  Card,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

const ProfileWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(4),
  padding: theme.spacing(2.5),
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const AvatarBox = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  // border: `0.5px solid ${theme.palette.grey.textColor}`,
}));

const ProfileInfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  border: '1px solid #e9e9e9',
}));

const ProfileHeader = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { userDetails } = useSelector((state) => state.user);

  return (
    <Card
      sx={{
        boxShadow: '0px 0px 26px 0px #0000001F',
      }}
    >
      <ProfileWrapper>
        <AvatarBox
          alt={userDetails.firstName + '' + userDetails.lastName}
          src={userDetails?.profileImage || ProfilePlaceHolder}
        />
        <ProfileInfoWrapper sx={{ flexGrow: 1 }}>
          <Typography variant="h4">
            {userDetails.firstName + ' ' + userDetails.lastName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {userDetails?.subUserType?.subUserID}
          </Typography>
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            {userDetails.facebook && (
              <Link
                to={`${userDetails?.facebook}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIconButton>
                  <FaceBookIcon />
                </SocialIconButton>
              </Link>
            )}
            {userDetails?.instagram && (
              <Link
                to={`${userDetails?.instagram}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIconButton>
                  <InstagramIcon />
                </SocialIconButton>
              </Link>
            )}
            {userDetails?.linkedIn && (
              <Link
                to={`${userDetails?.linkedIn}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIconButton>
                  <LinkedInIcon />
                </SocialIconButton>
              </Link>
            )}
            {userDetails?.x && (
              <Link
                to={`${userDetails?.x}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIconButton>
                  <TwitterIcon />
                </SocialIconButton>
              </Link>
            )}
            {userDetails?.youTube && (
              <Link
                to={`${userDetails?.youTube}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIconButton>
                  <YoutubeIcon />
                </SocialIconButton>
              </Link>
            )}
          </Stack>
        </ProfileInfoWrapper>
      </ProfileWrapper>
    </Card>
  );
};

export default ProfileHeader;
