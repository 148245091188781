import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { useSelector } from 'react-redux';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { authData } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authData?.data?.token) {
      navigate('home', { replace: true });
    }
  }, [authData, navigate]);

  return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
