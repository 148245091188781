import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { dispatch } from 'store';
import { getMemberProjectList } from 'store/slices/project';
import { getUserDetails } from 'store/slices/user';
import MemberProjectTable from 'ui-component/memberProjectTable';

const MembersView = () => {
  const dispatch = useDispatch();
  const { memberName } = useParams();
  const { memberProject, totalPage } = useSelector((state) => state.project);
  const [selectedUserId, setSelectedId] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getUserDetails({ slugId: memberName })).then((res) => {
      setSelectedId(res.data.id);
    });
  }, [memberName]);
  const fetchData = (
    page,
    rowsPerPage,
    orderBy,
    order,
    search,
    selectedUserId
  ) => {
    if (selectedUserId) {
      dispatch(
        getMemberProjectList({
          page: page,
          limit: rowsPerPage,
          sortBy: orderBy,
          sortDirection: order,
          search: search,
          projectType: { filter: 'Approve' },
          memberId: selectedUserId,
        })
      );
    }
  };
  const handleRedirect = (row) => {
    navigate(`${row.slugId}`);
  };
  return (
    <>
      <MemberProjectTable
        rows={memberProject || []}
        fetchData={fetchData}
        totalPage={totalPage}
        handleRedirect={handleRedirect}
        selectedUserId={selectedUserId}
      />
    </>
  );
};

export default MembersView;
