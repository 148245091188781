import { Box, Grid, IconButton, Link, Stack, Typography } from "@mui/material"; // Divider
import { styled, useTheme } from "@mui/material/styles";

import FbIcon from 'assets/images/landing/fb.svg';
import InIcon from 'assets/images/landing/in.svg';
import InstaIcon from 'assets/images/landing/insta.svg';
import MailIcon from 'assets/images/landing/mail.png';
import XIcon from 'assets/images/landing/x.svg';
import Logo from 'ui-component/Logo';

// =============================|| LANDING - FOOTER SECTION ||============================= //

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const IconBox = styled(Box)(({ theme }) => ({
  width: 35,
  height: 35,
  objectFit: 'contain',
  aspectRatio: 1 / 1,
}));

const CenterResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));

const EndResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));

const Footer = () => {
  const theme = useTheme();

  return (
    <>
      <Box px={4}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <Stack direction={'column'} gap={3}>
                  {/* <Typography variant="h4">
                    Join our pre-launch mailing list to qualify for 25% off your
                    membership fees for life.
                  </Typography> */}

                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Box component={'img'} src={MailIcon} sx={{ width: 30 }} />
                    <Typography
                      variant="h4"
                      component={Link}
                      href="mailto:info@prodculator.com"
                      sx={{
                        wordBreak: 'break-all',
                        overflowWrap: 'break-word',
                        textDecoration: 'none',
                        ':hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      info@prodculator.com
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <EndResponsiveBox>
                  <Typography variant="h4">Get in touch</Typography>
                </EndResponsiveBox>
              </Grid> */}
            </Grid>

            <Grid
              container
              spacing={{ md: 5, sm: 3, xs: 2 }}
              mt={{ md: 8, sm: 2, xs: 1 }}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid item xs={12} md={4}>
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                  <IconButtonWrapper
                    aria-label=""
                    component={Link}
                    target={'_blank'}
                    href={'https://www.linkedin.com/company/prodculator/'}
                  >
                    <IconBox component={'img'} src={InIcon} />
                  </IconButtonWrapper>
                  <IconButtonWrapper
                    aria-label=""
                    component={Link}
                    target={'_blank'}
                    href={'https://www.facebook.com/prodculator'}
                  >
                    <IconBox component={'img'} src={FbIcon} />
                  </IconButtonWrapper>
                  <IconButtonWrapper
                    aria-label=""
                    component={Link}
                    target={'_blank'}
                    href={'https://x.com/prodculator'}
                  >
                    <IconBox component={'img'} src={XIcon} />
                  </IconButtonWrapper>
                  <IconButtonWrapper
                    aria-label=""
                    component={Link}
                    target={'_blank'}
                    href={'https://www.instagram.com/prodculator/'}
                  >
                    <IconBox component={'img'} src={InstaIcon} />
                  </IconButtonWrapper>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <CenterResponsiveBox>
                  <Typography variant="h4">
                    © 2024 Produculator. All rights reserved
                  </Typography>
                </CenterResponsiveBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <EndResponsiveBox>
                  <Logo />
                </EndResponsiveBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
