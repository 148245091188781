// third-party
import { FormattedMessage } from "react-intl";

// assets
import { VscHome } from "react-icons/vsc";
import { CiStar } from 'react-icons/ci';
import { SlSettings } from 'react-icons/sl';
import { IoShareSocialSharp } from 'react-icons/io5';
import { IoIosLock } from 'react-icons/io';
import { FaCreditCard } from 'react-icons/fa';
import { MdPeopleOutline } from 'react-icons/md';
import { ReactComponent as PersonalInfoIcon } from 'assets/images/svg/personal-info.svg';

const icons = {
  VscHome,
  SlSettings,
  CiStar,
  PersonalInfoIcon,
  IoShareSocialSharp,
  IoIosLock,
  FaCreditCard,
  MdPeopleOutline,
};

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    {
      icon: icons.IconDashboard,
      type: 'group',
      children: [
        {
          id: 'personalInformation',
          title: <FormattedMessage id="Personal Information" />,
          type: 'item',
          url: '/p/personal-information',
          icon: icons.PersonalInfoIcon,
          prefix: ['p'],
          access: [1, 2],
        },
        {
          id: 'socialLinks',
          title: <FormattedMessage id="Social Links" />,
          type: 'item',
          url: '/p/social-links',
          icon: icons.IoShareSocialSharp,
          prefix: ['p'],
          access: [1, 2],
        },
        {
          id: 'securityAndNotifications',
          title: <FormattedMessage id="Security And Notifications" />,
          type: 'item',
          url: '/p/security-and-notifications',
          icon: icons.IoIosLock,
          prefix: ['p'],
          access: [1, 2],
        },
        {
          id: 'billingAndSubscriptions',
          title: <FormattedMessage id="Billing And Subscriptions" />,
          type: 'item',
          url: '/p/billing-and-subscriptions',
          icon: icons.FaCreditCard,
          prefix: ['p'],
          access: [1, 2],
        },
        {
          id: 'projectMatchingPreference',
          title: <FormattedMessage id="Project Matching Preference" />,
          type: 'item',
          url: '/f/preference-project',
          icon: icons.VscHome,
          access: [2],
          prefix: ['f'],
        },
        {
          id: 'starProject',
          title: <FormattedMessage id="Favorite Projects" />,
          type: 'item',
          url: '/f/star-project',
          icon: icons.CiStar,
          access: [2],
          prefix: ['f'],
        },
        {
          id: 'allProject',
          title: <FormattedMessage id="All Project" />,
          type: 'item',
          url: '/f/project',
          icon: icons.SlSettings,
          access: [2],
          prefix: ['f'],
        },
        {
          id: 'resources',
          title: <FormattedMessage id="Resources" />,
          type: 'item',
          url: '/f/resources',
          icon: icons.SlSettings,
          access: [2],
          prefix: ['f'],
        },
        {
          id: 'project',
          title: <FormattedMessage id="Project" />,
          type: 'item',
          url: '/c/project',
          icon: icons.VscHome,
          access: [1],
          prefix: ['c'],
        },
        {
          id: 'members',
          title: <FormattedMessage id="Members" />,
          type: 'item',
          url: '/c/member',
          icon: icons.MdPeopleOutline,
          access: [1],
          prefix: ['c'],
        },
        {
          id: 'resources',
          title: <FormattedMessage id="Resources" />,
          type: 'item',
          url: '/c/resources',
          icon: icons.SlSettings,
          access: [1],
          prefix: ['c'],
        },
      ],
    },
  ],
};

export default menuItems;
