import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// initial state
const initialState = {
  resourceList: {},
  totalPage: 0,
  resourceDetail: {},
};

const projectSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getResourcesSuccess(state, action) {
      state.resourceList = action.payload.items;
      state.totalPage = action.payload.total;
    },
    getResourcesDetailsSuccess(state, action) {
      state.resourceDetail = action.payload;
    },
  },
});

export const { hasError, getResourcesSuccess, getResourcesDetailsSuccess } =
  projectSlice.actions;

export default projectSlice.reducer;

export function getResourcesList(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/app/v1/resources/list', payload);
      dispatch(getResourcesSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function getResources(slugId) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/app/v1/resources/${slugId}`);
      dispatch(getResourcesDetailsSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
