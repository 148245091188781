import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import { useSelector } from "react-redux";
import Logo from "ui-component/Logo";

const LogoSection = () => {
  const { authData } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const redirect = () => {
    if (authData?.data?.token) {
      if (authData.data.userType.userTypeId === 1) {
        // Open /c/project in a new tab
        window.open("/home", "_blank", "noopener noreferrer");
      } else {
        // Open /f/preference-project in a new tab
        window.open("/home", "_blank", "noopener noreferrer");
      }
    } else {
      navigate("/home");
    }
  };

  return (
    <Link
      component={RouterLink}
      to="/home"
      onClick={redirect}
      aria-label="Prodculator"
    >
      <Logo />
    </Link>
  );
};

export default LogoSection;
