import { cloneElement } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material-ui
import {
  Box,
  MenuItem,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Logo from 'ui-component/Logo';

const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  ...theme.typography.h4,

  ':hover': {
    borderRadius: theme.spacing(0.5),
  },

  [theme.breakpoints.down('sm')]: {
    ...theme.typography.h6,
  },
}));

const HeaderStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(5),

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}));

function ElevationScroll({ children, window }) {
  const theme = useTheme();

  return cloneElement(children, {
    style: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.dark,
    },
  });
}

const Header = ({ ...others }) => {
  const navigate = useNavigate();

  const scrollToSection = (section) => {
    const headerOffset = 64; // adjust this value based on your header height
    const element = document.getElementById(section);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleAboutUsClick = () => {
    navigate('/home');
    setTimeout(() => {
      scrollToSection('hero-section');
    }, 100);
  };

  const handleContactUsClick = () => {
    navigate('/home');
    setTimeout(() => {
      scrollToSection('contact-us');
    }, 100);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <ElevationScroll {...others}>
      <MuiAppBar sx={{ position: 'sticky', top: 0 }}>
        <Box px={4}>
          <Toolbar
            sx={{
              py: 2,
              px: '0 !important',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <HeaderStack>
              <Box
                style={{ textDecoration: 'none', cursor: 'pointer' }}
                onClick={handleAboutUsClick}
              >
                <MenuItemWrapper>About us</MenuItemWrapper>
              </Box>
              <Typography
                component={RouterLink}
                to={'/home'}
                sx={{ textAlign: 'left', flexGrow: { lg: 0, md: 1 } }}
                onClick={scrollToTop}
              >
                <Logo />
              </Typography>
              <Box
                style={{ textDecoration: 'none', cursor: 'pointer' }}
                onClick={handleContactUsClick}
              >
                <MenuItemWrapper>Contact us</MenuItemWrapper>
              </Box>
            </HeaderStack>
          </Toolbar>
        </Box>
      </MuiAppBar>
    </ElevationScroll>
  );
};

export default Header;
