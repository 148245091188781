// material-ui
import {
  Avatar,
  Box,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import LogoSection from 'ui-component/logoSection';
// assets
import { IconMenu2 } from '@tabler/icons-react';
import { drawerWidth } from 'store/constant';
import HelpIcon from 'assets/images/svg/help.svg';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),

  ':hover': {
    borderRadius: theme.spacing(0.5),
  },
}));

const Header = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { layout } = useConfig();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: drawerWidth,
          p: '24px 16px',
          display: 'flex',
          //   borderRight: '1px solid #2323221A',
          borderRight: { xs: 'none', md: '1px solid #2323221A' },

          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>

        {layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
        (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              display: { xs: 'flex', md: 'none' },
              overflow: 'hidden',
              transition: 'all .2s ease-in-out',
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              '&:hover': {
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.light,
              },
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        ) : null}
      </Box>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={'center'}
        sx={{ flexGrow: 1, p: { xs: 1, md: 2 } }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }} />

        <MenuItemWrapper>
          <Box component={'img'} src={HelpIcon} alt="HelpIcon" />
          <Typography variant="h4" fontWeight={500}>
            Need help?
          </Typography>
        </MenuItemWrapper>
      </Stack>
    </>
  );
};

export default Header;
