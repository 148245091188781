import { lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";

// routes
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

// project import
import Loadable from "ui-component/Loadable";
import StepFormRoutes from "./StepFormRoutes";
import LandingRoutes from "./LandingRoutes";
import ProfileRoutes from "./ProfileRoutes";

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
  [
    { path: "/", element: <Navigate to="/home" /> },
    LoginRoutes,
    MainRoutes,
    StepFormRoutes,
    LandingRoutes,
    ProfileRoutes,
  ],
  {
    basename: process.env.REACT_APP_BASE_NAME,
  }
);

export default router;
