// material-ui

import { Box, useTheme } from "@mui/material";
import logoSvg from "assets/images/logo.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();
  const { authData } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleClick = () => {
    if (authData?.data?.token) {
      if (authData.data.userType.userTypeId === 1) {
        navigate('/c/project');
      } else {
        navigate('/f/preference-project');
      }
    } else {
      navigate('/home');
    }
  };
  return (
    <Box
      onClick={handleClick}
      component={"img"}
      src={logoSvg}
      alt="Prodculator"
      sx={{
        [theme.breakpoints.down("md")]: {
          width: "90%",
        },
      }}
      //   sx={{
      //     height: 50,
      //     width: "100%",
      //   }}
    />
  );
};

export default Logo;
