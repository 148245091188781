import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import axios from 'utils/axios';

// initial state
const initialState = {
  addProjectResponse: {},
  projectDetails: {},
  setPreferenceResponse: {},
  preferences: {}, // Ensure preferences is an object if it should store key-value pairs
  masterData: {},
  projectList: [],
  totalPage: 0,
  error: null, // Ensure error is part of the initial state if used
  favoriteProjectResponse: {},
  projectLink: {},
  memberProject: {},
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    addProjectSuccess(state, action) {
      state.addProjectResponse = action.payload;
    },
    projectDetailsSuccess(state, action) {
      state.projectDetails = action.payload;
    },
    setPreferenceSuccess(state, action) {
      state.setPreferenceResponse = action.payload;
    },
    getPreferenceSuccess(state, action) {
      state.preferences = action.payload.userPreference;
    },
    getMasterDataSuccess(state, action) {
      state.masterData = action.payload;
    },
    getProjectListSuccess(state, action) {
      state.projectList = action.payload.items;
      state.totalPage = action.payload.total;
    },
    favoriteProjectSuccess(state, action) {
      state.favoriteProjectResponse = action.payload.items;
    },
    getProjectLinkSuccess(state, action) {
      state.projectLink = action.payload.items;
    },
    getMemberProjectSuccess(state, action) {
      state.memberProject = action.payload.items;
    },
  },
});

export const {
  hasError,
  addProjectSuccess,
  setPreferenceSuccess,
  getPreferenceSuccess,
  getMasterDataSuccess,
  getProjectListSuccess,
  projectDetailsSuccess,
  favoriteProjectSuccess,
  getProjectLinkSuccess,
  getMemberProjectSuccess,
} = projectSlice.actions;

export default projectSlice.reducer;

export function addProject(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/app/v1/project', payload);
      dispatch(addProjectSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function getProjectDetails(projectName) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/app/v1/project/${projectName}`);
      dispatch(projectDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function setPreference(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        '/app/v1/project/set-preference',
        payload
      );
      dispatch(setPreferenceSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function getPreference() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/app/v1/project/get-preference');
      dispatch(getPreferenceSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function getMasterData() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/app/v1/project/masters');
      dispatch(getMasterDataSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getProjectList(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/app/v1/project/list?listType=${payload.listType}`,
        payload
      );
      dispatch(getProjectListSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
export function favoriteProject(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/app/v1/likesProject', payload);
      dispatch(favoriteProjectSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
export function getProjectLink(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/app/v1/likesProject/${payload.projectId}`
      );
      dispatch(getProjectLinkSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteProject(projectId) {
  return async () => {
    try {
      const response = await axios.delete(`/app/v1/project/${projectId}`);
      return response.data;
    } catch (error) {}
  };
}
export function getMemberProjectList(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/app/v1/project/list?memberId=${payload.memberId}`,
        payload
      );
      dispatch(getMemberProjectSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

