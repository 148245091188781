import { AppBar, Box, MenuItem, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/system';
import HelpIcon from 'assets/images/svg/help.svg';
import LogoSection from 'ui-component/logoSection';

import { Outlet } from 'react-router-dom';

const StickyAppBar = styled(AppBar)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.bgMain,
    boxShadow: 'none',
    borderBottom: '1px solid rgba(35, 35, 35, 0.1)'
}));

const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),

    ':hover': {
        borderRadius: theme.spacing(0.5)
    }
}));

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <StickyAppBar>
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <LogoSection />

                <MenuItemWrapper>
                    <Box component={'img'} src={HelpIcon} alt="HelpIcon" />
                    <Typography variant="h4" fontWeight={500}>
                        Need help?
                    </Typography>
                </MenuItemWrapper>
            </Toolbar>
        </StickyAppBar>
        <Outlet />
    </>
);

export default MinimalLayout;
