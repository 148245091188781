// material-ui
import { useTheme } from '@emotion/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@mui/material/styles';

// styles
const LoaderWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 9999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.5)',
});

// ==============================|| LOADER ||============================== //

const Loader = () => {
  const theme = useTheme();
  return (
    <LoaderWrapper>
      <CircularProgress color={theme.palette.secondary.light} />
    </LoaderWrapper>
  );
};

export default Loader;
