import { lazy } from "react";

// project imports
import LandingLayout from "layout/LandingLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";
import GuestGuard from "utils/route-guard/GuestGuard";


const Landing = Loadable(lazy(() => import('../views/pages/landing')));
const TaxCalculator = Loadable(
  lazy(() => import('../views/pages/tax-calculator'))
);
const IncentiveCalculator = Loadable(
  lazy(() => import('../views/pages/tax-calculator'))
);
const Eligible = Loadable(lazy(() => import('../views/pages/tax-calculator')));
const FreelanceTaxCalculator = Loadable(
  lazy(() => import('views/pages/freelanceTaxCalculator'))
);

const LandingRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        <LandingLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/home',
      element: <Landing />,
    },
    {
      path: '/tax-calculator',
      element: <TaxCalculator />,
    },
    {
      path: '/freelance-tax-calculator',
      element: <FreelanceTaxCalculator />,
    },
    {
      path: '/incentive-calculator',
      element: <IncentiveCalculator />,
    },
    {
      path: '/eligible',
      element: <Eligible />,
    },
  ],
};

export default LandingRoutes;
