import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// initial state
const initialState = {
  projectsPrice: {},
  paymentInitiate: {},
};

const projectSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getPriceSuccess(state, action) {
      state.projectsPrice = action.payload;
    },
    paymentInitiateSuccess(state, action) {
      state.paymentInitiate = action.payload;
    },
  },
});

export const { hasError, getPriceSuccess, paymentInitiateSuccess } =
  projectSlice.actions;

export default projectSlice.reducer;

export function getPrice(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/app/v1/payment/getPrice', payload);
      dispatch(getPriceSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function paymentInitiate(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        '/app/v1/payment/paymentInitiate',
        payload
      );
      dispatch(paymentInitiateSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
