/* eslint-disable react/prop-types */
import { Button, useTheme } from '@mui/material';
import React from 'react';
import AnimateButton from 'ui-component/extended/AnimateButton';

const LightButton = (props) => {
  const theme = useTheme();
  return (
    <>
      <AnimateButton>
        <Button
          sx={{
            background: theme.palette.background.paper,
            color: theme.palette.grey.textColor,
            boxShadow: 'none',
            minWidth: 120,
            border: '1px solid #2323221A',
            // border: '1px solid rgba(35, 35, 34, 0.1)',
            ':hover': {
              background: theme.palette.background.paper,
              boxShadow: 'none',
            },
          }}
          type="button"
          variant="contained"
          {...props}
        >
          {props.title}
        </Button>
      </AnimateButton>
    </>
  );
};

export default LightButton;
