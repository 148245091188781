/* eslint-disable react/prop-types */
import { Button, useTheme } from "@mui/material";
import AnimateButton from "ui-component/extended/AnimateButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const DarkButton = (props) => {
  const theme = useTheme();
  return (
    <>
      <AnimateButton>
        <Button
          sx={{
            background: theme.palette.common.black,
            boxShadow: "none",
            color: theme.palette.common.white,
            minWidth: 120,
            [theme.breakpoints.down("sm")]: {
              // Adjust styles for screens smaller than or equal to 'sm'
              // minWidth: 80,
              // padding: "8px 16px", // Adjust padding to maintain button proportions
            },

            ":hover": {
              background: theme.palette.common.black,
              boxShadow: "none",
            },
          }}
          type="button"
          variant="contained"
          {...props}
        >
          {props?.isLoader ? (
            <CircularProgress size={24} color="white" />
          ) : (
            props.title
          )}
        </Button>
      </AnimateButton>
    </>
  );
};

export default DarkButton;
