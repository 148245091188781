import { Outlet } from "react-router-dom";
import Header from "./Header/Index";
import Footer from "./Footer/Index";
import { styled, useTheme } from "@mui/material";

const SectionWrapper = styled("div")({
  paddingTop: 100,
  paddingBottom: 30,
});

const LayoutWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
});

const ContentWrapper = styled("div")({
  position: "relative",
  flex: 1,
});

const LandingLayout = () => {
  const theme = useTheme();
  return (
    <LayoutWrapper>
      <Header />
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
      <SectionWrapper
        sx={{
          background: theme.palette.secondary.main,
        }}
      >
        <Footer />
      </SectionWrapper>
    </LayoutWrapper>
  );
};

export default LandingLayout;
