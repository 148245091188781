import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import { getPreference, getProjectList } from 'store/slices/project';
import ProjectTable from 'ui-component/projectTable';

const StarProject = () => {
  const { projectList, totalPage } = useSelector((state) => state.project);
  const navigate = useNavigate();

  const fetchData = (page, rowsPerPage, orderBy, order, search) => {
    dispatch(
      getProjectList({
        page: page,
        limit: rowsPerPage,
        sortBy: orderBy,
        sortDirection: order,
        search: search,
        filter: { projectType: 'Approve' },
        listType: 'favorite',
      })
    );
  };
  const handleRedirect = (row) => {
    navigate(`${row.slugId}`);
  };
  return (
    <>
      <ProjectTable
        title="Favorite Project List"
        rows={projectList || []}
        fetchData={fetchData}
        totalPage={totalPage}
        handleRedirect={handleRedirect}
      />
    </>
  );
};

export default StarProject;
